import API from ".";

const searchMusic = (user, query, type, order_by, instrument, difficulty, time_period, key_signature, composer, offset) => {

  query = query ? query : "-"
  type = type ? type : "all"
  order_by = order_by === "shuffle" ? "shuffle" : type === "starred" ? "starred" : type === "viewed" ? "viewed" : "standard"
  instrument = instrument ? instrument : "-"
  difficulty = difficulty ? difficulty : "-"
  time_period = time_period ? time_period : "-"
  key_signature = key_signature ? key_signature : "-"
  composer = composer ? composer : "-"
  offset = offset ? offset : 0

  let url = '/searchMusic?query=' + query +
    "&type=" + type +
    "&order_by=" + order_by +
    "&instrument=" + instrument +
    "&difficulty=" + difficulty +
    "&time_period=" + time_period +
    "&key_signature=" + key_signature +
    "&composer=" + composer +
    "&offset=" + parseInt(offset);

  return new API(user).getRequest(url).then(json => {
    return { "pieces": json.music, "count": json.count };
  });
}

export default searchMusic;